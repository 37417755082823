<template>
    <div>
        <div class="tile is-ancestor">
            <div class="tile is-parent space">
                <article class="tile is-child box section-style titles">
                    <p>
                        {{ i18n('Name') }}: {{ programs.name }}
                    </p>
                </article>
            </div>
            <div class="tile is-parent space">
                <article class="tile is-child box section-style titles">
                    <p>
                        {{ i18n('Point Value') }}: {{ programs.pointValue }}
                    </p>
                </article>
            </div>
        </div>
        <a class="column is-flex is-full-mobile" @click="$router.push('/programs')">
            <button class="button butt control field">
                {{ i18n('Back') }}
                <fa class="i-icon" icon="arrow-left"/>
            </button>
        </a>
        <br>
        <div class="container columns is-centered is-half has-text-centered">
            <img :src='imgBase64'>
        </div>
    </div>
</template>

<script>
import { FontAwesomeIcon as Fa } from '@fortawesome/vue-fontawesome';

export default {
    name: 'Show',

    components: { Fa },

    inject: ['i18n', 'http', 'route', 'routerErrorHandler', 'toastr'],

    data: () => ({
        programs: {},
        imgBase64: null,
    }),

    mounted() {
        this.fetch();
    },

    methods: {
        fetch() {
            this.http.get(this.route('programs.show', this.$route.params.programs))
                .then(({ data }) => {
                    this.programs = data.programs;
                    this.imgBase64 = `data:${this.programs.imageType};base64, ${this.programs.content}`;
                }).catch(this.errorHandler);
        },
    },
};
</script>

<style lang="scss">
@import '../../../sass/enso.scss';

.section-style{
    background: radial-gradient(circle, rgba(72,95,199,1) 35%, rgba(9,9,121,1) 100%);
    padding: 10px;
}

.titles{
    font-family: Bahnschrift,serif;
    text-transform: capitalize;
    font-weight: bold;
    color: $secondary;
}

.titles1{
    text-align: center;
    font-family: Bahnschrift,serif;
    text-transform: capitalize;
    font-weight: bold;
    color: $secondary;
}

.space{
    padding: 3px !important;
    &.btn {
        cursor: pointer;
    }
}

.butt{
    background-color: $orange;
    border-color: transparent;
    color: $secondary !important;
}

.i-icon {
    margin-left: 5px;
}

</style>
